#footer {
  padding-top: 2.5rem;
  
  @media all and (max-width: 992px) {
      padding-top:0;
  }
}

.block-contact {
    font-size: 16px;
    color: white;

    .block-contact-title {
        color: $gray-darker;
    }

    .navbar-toggler .material-icons {
        color: $gray-darker;
    }

    @include media-breakpoint-down(sm) {
        #contact-infos {
          padding: 0.625rem;
          padding-top: 0;
        }
    }
}

.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}

.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-size: $base-font-size;
}

.block-social {
  text-align: right;

  ul {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0.125rem;
      cursor: pointer;
      background-color: $gray-light;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        width: 2rem;
        height: 2rem;
        margin-right: 1.3rem;
        margin-bottom: 1.3rem;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-primary;
      }

      a {
        display: block;
        height: 100%;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;

        &:hover {
          color: transparent;
        }
      }
    }
  }
}

/*.facebook {
  background-image: url("../img/facebook.svg");

  &::before {
    content: "";
    background-image: url("../img/facebook-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/facebook-gray.svg");

    &:hover {
      background-image: url("../img/facebook-blue.svg");
    }
  }
}*/

.twitter {
  background-image: url("../img/twitter.svg");

  &::before {
    content: "";
    background-image: url("../img/twitter-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/twitter-gray.svg");

    &:hover {
      background-image: url("../img/twitter-blue.svg");
    }
  }
}

.linkedin {
  background-image: url("../img/linkedin.svg");
  background-position: center center;
}

.rss {
  background-image: url("../img/rss.svg");
}

.youtube {
  background-image: url("../img/youtube.svg");
}

.googleplus {
  background-image: url("../img/gplus.svg");

  &::before {
    content: "";
    background-image: url("../img/gplus-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/gplus-gray.svg");

    &:hover {
      background-image: url("../img/gplus-blue.svg");
    }
  }
}

#block_myaccount_infos {
    .myaccount-title a {
        color: white;
        font-size: 20px;
        letter-spacing: .6px;
        margin-bottom: 30px;
    }
}

.pinterest {
  background-image: url("../img/pinterest.svg");

  &::before {
    content: "";
    background-image: url("../img/pinterest-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/pinterest-gray.svg");

    &:hover {
      background-image: url("../img/pinterest-blue.svg");
    }
  }
}

.vimeo {
  background-image: url("../img/vimeo.svg");
}

.instagram {
  background-image: url("../img/instagram.svg");
}

.footer-container {
    padding-top: 8rem;
    overflow: hidden;
    background: black;
    margin-top: 67px;
    
    .h3,
    .h4 {
      margin-bottom: 1rem;
      font-size: 1rem;
      color: white;
    }

    li {
        margin-bottom: 14px;
    }

    li a {
        font-size: 13px;
        color: #ffffff;
        letter-spacing: 0.4px;

        &:hover {
          color: $brand-primary;
        }
    }
    
    .column {
        @media all and (min-width: 992px) {
            margin-top: 20px;
        }
    }
    
    .col-md-2.column_2 {
        @media all and (min-width: 992px) {
            width: 21%;
        }
    }
    
    .block_newsletter {
        @media all and (min-width: 992px) {
            margin-left: 3%;
        }
    }
    
    .copyrights-and-socials {
        color: white;
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        letter-spacing: 0.35px;
        
        @media all and (min-width: 992px) {
            margin-top: 87px;
            padding-bottom: 61px;
        }
        
        @media all and (max-width: 992px) {
            color: #fff;
            flex-direction: column;
            text-align: center;
        }
        
        .copyright {
            @media all and (max-width: 992px) {
                order: 2;
            }
        }
        
        .socials {
            transform: translateX(-38%);
            margin-top: -10px;
            vertical-align: top;
            
            
            @media all and (max-width: 992px) {
                transform: none;
                width: 100%;
                order: 1;
                margin: 50px 0 35px;
            }
            
            a {
                margin: 0 3px;
                
                &:hover {
                    svg {
                        path {
                            fill: #fff;
                        }
                        
                        .svg-background {
                            fill: #e1375c;
                        }
                    }
                }
            }
        }
        
        .project {
            @media all and (max-width: 992px) {
                order: 3;
                margin-top: 7px;
                margin-bottom: 30px;
            }
        }
        
        a {
            color: white;
        }
    }
}

.links {
    .h3 {
        font-size: 20px;
        letter-spacing: 0;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: 500;
    }
    
    .collapse {
        display: inherit;
    }
}

 @media all and (max-width: 992px) {
  .block_newsletter {
    padding-bottom: 0.625rem;
  }

  .footer-container {
    margin-top: 0;
    box-shadow: none;
    padding-top: 0px;
    
    @media all and (max-width: 992px) {
        > .container {
            > .row {
                display: flex;
                flex-direction: column;
                
                .column_1 {
                    order: 2;
                }
                
                .column_2 {
                    order: 3;
                }
                
                #block_myaccount_infos {
                    order: 4;    
                }
                
                .block_newsletter {
                    order: 1;
                }
            }
        }
    }
    
    .wrapper {
      /* stylelint-disable */
      padding-right: 0;
      padding-left: 0;
      /* stylelint-enable */
      
      &#block_myaccount_infos {
          padding: 0 15px;
      }
    }

    .links {
      
      .h3 {
        font-size: 1rem;
        line-height: 1.5;
      }

      ul {
        margin-bottom: 0;
        background-color: black;

        > li {
            padding: 10px 15px;
            font-weight: 600;
            border-bottom: 1px solid #1c1c1c;
            margin-bottom: 0;

            a {
                color: #ffffff;
                font-weight: 300;
            }
        }
      }
      
      
    }
  }

  .links {
    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem 0;
      cursor: pointer;
      border-bottom: 1px solid #3a3a3a;

      .collapse-icons .remove {
        display: none;
        color: white;
      }
    }

    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }

      .remove {
        display: block;
        font-size: 21px;
      }
    }

    .navbar-toggler {
        display: inline-block;
        padding: 0;
        width: auto;
        height: auto;
        
        .material-icons {
            width: auto;
            height: auto;
            font-size: 1px;
            
            &.add {
                
            }
            
            img {
                vertical-align: top;
            }
        }
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }

  .block-contact {
    padding-left: 1.5rem;
  }
}
