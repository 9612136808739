#header {
    position: relative;
    z-index: 999;
    color: black;
    background: $white;
  
    @media all and (min-width: 992px) {
        padding-bottom: 25px;
    }
  
    .logo {
      max-width: 100%;
      height: auto;
    }

    #_desktop_logo {
        h1 {
            margin-bottom: 0;
        }
    }


    .header-nav {
        .mobile {
            width: 100%;
            float: left;
            text-align: center;
            position: relative;
            padding-bottom: 18px;
        }

        #menu-icon {
            margin: 23px 0 0 12px;
            vertical-align: middle;
            cursor: pointer;

            .material-icons {
              line-height: 50px;
            }
        }

        .right-nav {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-end;
        }

    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }

    .user-info {
      margin-top: 0.9375rem;
      margin-left: 2.5rem;
      text-align: right;
      white-space: nowrap;

      .account {
        margin-left: $small-space;
      }
    }

    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }

    .cart-preview {

      .shopping-cart {
        color: $gray;
        vertical-align: middle;
      }

      .body {
        display: none;
      }
    }
    
    #_mobile_user_info {
        margin-top: 23px;
        margin-right: 15px;
    }

    .blockcart {
      text-align: center;
      white-space: nowrap;

      @media all and (max-width: 991px) {
            margin-top: 22px;
            margin-right: 20px;
      }

      .header {
        margin-top: 0.125rem;
        .cart-image {
            @media all and (max-width: 991px) {
                max-width: 16px;
            }
        }
        
      }
    }

    #_desktop_contact_link {
      display: inline-block;

      #contact-link {
        margin-top: 0.9375rem;
      }
    }

    .search-widget {
      margin-top: 0.2rem;
    }

    .material-icons {
      line-height: inherit;

      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }
  
  
  .header-my-account-holder a {
        background-image: url(../../assets/img/trena_assets/svg/account.svg);
        width: 24px;
        height: 24px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        vertical-align: top;
        margin-top: -3px;
        
        &:hover {
            background-image: url(../../assets/img/trena_assets/svg/account-hover.svg);
        }
        
        @media all and (max-width: 992px) { 
            width: 17px;
            height: 24px;
            margin-top: -1px;
            background-size: contain;
        }
    }
    
    
    #wishlist-link span {
        width: 34px;
        height: 28px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center; 
        vertical-align: top;
        margin-top: -3px;
        background-image: url(../../assets/img/trena_assets/svg/favourite.svg);
        
        &:hover {
            background-image: url(../../assets/img/trena_assets/svg/favourite-hover.svg);
        }
    }
    
    
    .blockcart .header.dropdown {
        width: 22px;
        height: 24px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        vertical-align: top;
        margin-top: -3px;
        background-image: url(../../assets/img/trena_assets/svg/cart.svg);
        
        &:hover {
            background-image: url(../../assets/img/trena_assets/svg/cart-hover.svg);
        }
        
        @media all and (max-width: 992px) {
            width: 16px;
            height: 31px;
            background-size: contain;
        }
        
        a[data-toggle="dropdown"] {
            @media all and (min-width: 992px) {
                width: 30px;
                height: 30px;
                display: block;
            }
        }
    }

  .header-top {
    padding: 1.25rem 0;
    margin-bottom: 24px;
    
    @media all and (min-width: 992px) {
        &.fixed {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 9;
            box-shadow: 9px 1px 7px rgba(0,0,0,0.2);
            padding: 10px 0;
        }
    }
    
     
    
    @media all and (max-width: 991px) {
        padding: 0;
        margin-bottom: 0px;
    }

    > .container {
        position: relative;
        max-width: 1859px;

        > .row:first-of-type {
            display: flex;
            align-items: center;
        }
    }

    .position-static {
        position: static;
        margin-top: 42px;
        
        @media all and (max-width: 991px) {
            margin-top: 0;
        }
    }

    a[data-depth="0"] {
      color: $gray;
      text-transform: uppercase;
    }

    .search-widget {
      float: right;
    }
    
    .right-nav {
        text-align: right;
        margin-top: 3px;
        
        @media all and (max-width: 991px) {
            display: none;
        }
    }
    
    
    #_desktop_cart, #_desktop_user_info, #wishlist-link {
        display: inline-block;
        margin-right: 8px;
    }
    
    
    @media all and (max-width: 991px) {
        #wishlist-link {
            display: none;
        }
    }
  }

  .top-menu-link {
    margin-left: $medium-space;
  }

  @include media-breakpoint-down(lg) {
    .top-logo {
      align-items: center;
      min-height: 50px;

      img {
        width: auto;
        max-height: 2rem;
      }

      > h1 {
        margin: 0;
      }
    }
  }
}


.header-banner {
    overflow: hidden;
    margin-bottom: 11px;
        
    @media all and (max-width: 991px) {
        display: none;
    }
    
    .animated-banner {
        background: black;
        text-align: left;
        width: 3950px;
        white-space: nowrap;
        
        .elem {
            display: inline-block;
            text-align: center;
            font-size: 14px;
            padding: 6px;
            
            &.first {
                animation: bannermove 120s linear infinite;
            }
            
            .text {
                color: white;
                font-weight: 600;
            }
            
            .call-to-action {
                color: white;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0 130px;
                text-decoration: underline;
            }
        }
    }
    
    .top-infos {
        padding: 17px 37px 12px;
        width: 100%;
        border-bottom: #f6f6f6 1px solid;
        
        &:after {
            content: "";
            display: table;
            clear:both;
        }
        
        .left-side {
            float: left;
            font-size: 12px;
            
            .phone {
                font-weight: 600;
                color: black;
            }
            
            .mail {
                font-weight: 600;
                color: black;
            }
            
            a:hover {
                color: #e1375c;
            }
        }
        
        .right-side {
            float: right;
            
            .link {
                color: #000;
                margin-left: 29px;
                font-size: 13px;
                font-weight: 400;
    
                &:hover {
                    color: #e1375c;
                }
            }
        }
    }
}

@keyframes bannermove {
    0% {
       margin-left: 0px;
    }
    100% {
       margin-left: -3950px;
    }
}
 