#product {
    #content {
        position: relative;
        margin: 0 auto;
        float: left;
        clear: both;
        
        @media all and (max-width: 992px) {
            z-index: 1;
            width: 100%;
        }
        
        .js-qv-mask {
            .slick-slider {
                img {
                    width: 100%;
                    height: auto;
                }
                
                .slick-next {
                    right: 0px;
                    background: white;
                }
                
                .slick-prev {
                    left: 0px;
                    z-index: 1;
                    background: white;
                }
                
                .slick-prev, .slick-next {
                    &:before {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
  
    .product-container {
        .h1 {
            font-size: 30px;
            letter-spacing: 0;
            line-height: 1.5;
            font-weight: 600;
            
            @media all and (max-width: 992px) {
                margin-top: 13px;
                font-size: 16px;
                display: inline-block;
            }
        }
        
        .product-flags {
            position: static;
            display: flex;
            margin-left: -20px;
            margin-bottom: 12px;
                
            @media all and (max-width: 992px) {
                width: auto;
                float: left;
                margin-left: -10px;
            }
            .product-flag {
                display: inline-block;
                order: 2;
                height: fit-content;
                margin-top: 10px;
                margin-left: 10px;
                
                @media all and (max-width: 992px) {
                    margin-top: 5px;
                }
                
                &.discount {
                    position: static;
                    order: 1;
                    margin-top: 0px;
                }
            }
        }
        
        .js-images-container {
            @media all and (min-width: 991px) {
                display: flex;
            }
        }
    }
    
    .product-manufacturer-info {
/*        position: absolute;
        right: 34px;
        top: 17px;
        letter-spacing: 0px;*/
        font-size: 15px;
        display: inline-block;
        
        @media all and (max-width: 992px) {
            position: static;
            float: right;
            font-size: 12px;
            margin-top: 5px;
        }
        
        .title {
            color: #878484;
        }
        
        .name {
            color: black;
            text-decoration: underline;
        }
    }
    
    .product-detailed-informations {
        border-top: 2px solid whitesmoke;
        
        @media all and (max-width: 992px) {
            padding: 5px 0 5px;
            border-bottom: 2px solid #f5f5f5;
        }
        
        .box {
            display: inline-block;
            padding: 20px 13px;
            
            @media all and (max-width: 992px) {
                padding: 2px 0px;
                width: 100%;    
            }
            
            &:nth-child(1) {
                margin-left: -13px;
                
                @media all and (max-width: 992px) {
                    margin-left: 0;
                }
            }
            
            .label {
                color: #4e4e4e;
                letter-spacing: 0px;
                font-size: 15px;
                margin-bottom: 0;
                font-weight: 300;
                
                @media all and (max-width: 992px) {
                    font-size: 13px;
                }
            }
            
            .value {
                color: black;
                font-size: 15px;
                letter-spacing: 0px;
                
                @media all and (max-width: 992px) {
                    font-size: 13px;
                }
            }
            
            span {
                @media all and (max-width: 992px) {
                    font-size: 13px;
                }
            }
        }
    }
    
    .product-additional-info-cart {
        border-top: 2px solid whitesmoke;
        border-bottom: 2px solid whitesmoke;
        
        @media all and (max-width: 992px) {
            border-bottom: 0;
        }
        
        .box {
            display: inline-block;
            padding: 10px 15px;
            
            @media all and (max-width: 992px) {
                padding: 10px 5px;
            }
            
            &:nth-child(1) {
                margin-left: -15px;
                
                @media all and (max-width: 992px) {
                    margin-left: -5px;
                }
            }
            
            .text {
                margin-left: 10px;
                
                @media all and (max-width: 992px) {
                    margin-left: 2px;
                    font-size: 13px;
                    font-weight: 500;
                    color: black;
                }
            }
        }
    }
    
    .share {
        a {
            color: black;
            cursor: pointer;
        }
    }
    
    #share-modal {
        .modal-dialog {
            max-width: 360px;
        }
        
        .share-box {
            padding: 10px 0 30px;
            text-align: center;
                
            a {
                margin: 0 8px;
            }
        }
    }
    
    .products-tabs { 
        
        @media all and (max-width: 992px) {
            p,ul,li {
                font-size: 13px;
                font-weight: 300;
                line-height: 1.9!important;
            }
        }
        .tab-content {
            border-bottom: 2px solid whitesmoke;
            margin-bottom: 0;
            
            .tab-header {
                font-size: 24px;
                font-weight: 600;
                color: black;
                padding: 16px 0;
                cursor: pointer;
                position: relative;
                
                @media all and (max-width: 992px) {
                    font-size: 16px;
                    font-weight: 500;
                    border-bottom: 2px solid #f5f5f5;
                }
                
                &[aria-expanded="false"]{
                    @media all and (max-width: 992px) {
                        border-bottom: 0;
                    }
                    
                    &:after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 20px; 
                        background: url('../../assets/img/trena_assets/svg/arrow_bottom.svg') no-repeat;
                        position: absolute;
                        right: 0;
                        top: 24px;
                        
                        @media all and (max-width: 992px) {
                            top: 20px;
                        }
                    }   
                }  
                
                &[aria-expanded="true"]{
                    &:after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 20px; 
                        background: url('../../assets/img/trena_assets/svg/close-black.svg') no-repeat;
                        position: absolute;
                        right: 0;
                        top: 24px;
                        
                        @media all and (max-width: 992px) {
                            top: 20px;
                        }
                    }   
                }  
            }
            
            .card-body {
                padding: 16px 0 35px;
                line-height: 1.5;    
                
                @media all and (max-width: 992px) {
                    padding: 16px 0 15px;
                }
                
                ul {
                    list-style: disc;
                    padding-left: 30px;
                }
            }
        }
        
        #data-delivery {
            .table {
                td {
                    font-size: 13px;
                }  
                
                .price {
                    font-size: 13px;
                }
            } 
        }
    }
    
    
    .products-home-slider {
        .products-section-title {
            margin-top: 100px;
            margin-bottom: 72px;
            
            @media all and (max-width: 992px) {
                font-size: 18px;
                margin-top: 30px;
                margin-bottom: 25px;
            }
            
            span {
                @media all and (max-width: 992px) {
                    max-width: initial;
                    text-align: left;
                    padding: 0;
                }
            }
        }   
    }
}

.product-price {
    display: inline-block;
    max-width: 100%;
    color: black;
    font-size: 38px;
    
    @media all and (max-width: 992px) {
        font-size: 22px;
    }
}

#product-description-short {
  color: $gray-darker;

  ul,
  ol {
    padding-left: 0;
    list-style-position: inside;

    li {
      font-size: 0.9375rem;
      color: $gray;
    }
  }

  ul {
    list-style-type: disc;
  }
}

.product-information {
  font-size: $font-size-lg;
  color: $gray-darker;
  
  @media all and (max-width: 992px) {
        display: flex;
        flex-direction: column;
        
        .product-actions {
            order: 1;
        }
        
        .product-detailed-informations {
            order: 3;
        }
        
        .product-additional-info-cart {
            order: 2;
        }
        
        .accordion.products-tabs {
            order: 4;
        }
  }

  .manufacturer-logo {
    max-height: 4rem;
    margin-bottom: 1rem;
    border: 2px solid $gray-light;
  }

  .product-description {
    img {
      max-width: 100%;
      height: auto;
    }

    ul,
    ol {
      padding-left: 0.75rem;
      list-style-position: inside;

      li {
        font-size: 0.9375rem;
        color: $gray;
      }
    }

    ul {
      list-style-type: disc;
    }
  }
}

.input-color {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  opacity: 0;
}

.input-container {
  position: relative;
}

.input-radio {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.input-color,
.input-radio {
  &:checked + span,
  &:hover + span {
    border: 2px solid $gray-darker;
  }
}

.radio-label {
  display: inline-block;
  padding: 0.125rem $small-space;
  font-weight: 600;
  background: $white;
  border: 2px solid $white;
}

.product-actions {
  .control-label {
    display: block;
    width: 100%;
    margin-bottom: 0.375rem;
  }

  .add-to-cart {
    line-height: inherit;
    padding: 19px 184px;
    text-transform: none;
    background: #000;
    letter-spacing: 0px;

    @media all and (max-width: 992px) {
        background: #e44a6d;
        padding: 19px 17px;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
    }
    .material-icons {
      line-height: inherit;
    }
    
    &:hover {
        background-color: #e44a6d;
    }
  }
  
    .wishlist-button-add {
        margin-top: 8px;
        
        @media all and (max-width: 992px) {
            position: absolute;
            right: 17px;
            margin-top: -59px;
        }
        
        i {
            @media all and (max-width: 992px) {
                font-size: 25px;
            }
        }
    }
  
    .input-group.bootstrap-touchspin {
        
        .input-group-btn {
            float: left;
            height: 60px;
            font-size: 27px;
            border: 1px solid #ededed;
            
            
            &:nth-child(5) {
                border-left: none;
            }
            
            .btn {
                display: block;
                -webkit-box-align: inherit;
                -ms-flex-align: inherit;
                align-items: inherit;
                height: 100%;
                padding: 0.25rem 1rem;
                margin-left: 0;
                font-weight: 500;
                text-transform: uppercase;
                border: 0;
                box-shadow: none;
                color: #000;
                font-size: 27px;
                
                @media all and (max-width: 992px) {
                    padding: 0.25rem 5px;
                    font-weight: 300;
                    font-size: 22px;
                }
            }
        }
    }
  
}

.product-quantity {
  display: flex;
  flex-wrap: wrap;
  
  @media all and (max-width: 992px) {
    margin-bottom: 9px;   
  }

  .qty,
  .add {
    display: inline-flex;
    float: left;
    margin-bottom: 0.5rem;
  }
  
  .add {
      @media all and (max-width: 992px) {
          width: calc(100% - 92px);
          margin-left: 5px;
      }
  }

  .qty {
    margin-right: 0.4rem;
  }

  #quantity_wanted {
    width: 3rem;
    height: 60px;
    padding: 0.175rem 0.5rem;
    color: #232323;
    background-color: #fff;
    border-left: none;
    border-right: none;
    font-weight: 600;
    border-color: #ededed;
    text-align: center;
    
    @media all and (max-width: 992px) {
        width: 32px;
    }
  }

  .input-group-btn-vertical {
    width: auto;

    .btn {
      padding: 0.5rem 0.6875rem;

      i {
        top: 0.125rem;
        left: 0.1875rem;
        font-size: 1rem;
      }
    }
  }

  .btn-touchspin {
    height: 1.438rem;
  }
}

.product-discounts {
  margin-bottom: 1.5rem;

  > .product-discounts-title {
    font-size: $font-size-sm;
    font-weight: 600;
  }

  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      text-align: center;
      background: $white;
      border: 0.3125rem $gray-light solid;
    }

    tbody tr {
      background: $gray-lighter;

      &:nth-of-type(even) {
        background: $white;
      }

      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.3125rem $gray-light solid;
      }
    }
  }
}

.product-prices {
  margin-top: 11px;
  
  @media all and (max-width: 992px) {
    margin-top: 21px;
    margin-bottom: 10px;
  }
  div {
    margin-bottom: 0.625rem;
  }

  .tax-shipping-delivery-label {
    font-size: 0.8125rem;
    color: $gray;

    .delivery-information {
      padding: 0 0 0 2px;
      font-weight: 700;

      &::before {
        padding: 0 2px 0 0;
        content: "-";
      }
    }
  }
}

.product-discount {
  color: #8e8e8e;
  display: inline-block;
  
  .regular-price {
    margin-right: 20px;
    font-weight: 300;
    font-size: 29px;
    text-decoration: line-through;
    
    @media all and (max-width: 992px) {
        margin-right: 5px;
        font-size: 16px; 
    }
  }
}

.has-discount {
  &.product-price,
  p {
    color: #e1375c;
  }

  .discount {
    display: inline-block;
    padding: 0.3125rem $small-space;
    margin-left: $small-space;
    font-size: $font-size-base;
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
    background: $brand-secondary;
  }
}

.product-unit-price,
.has-discount .product-unit-price {
  margin-bottom: 0;
  overflow: hidden;
  font-size: $font-size-xs;
  color: $gray;
  text-overflow: ellipsis;
}

.tabs {
  padding: $medium-space $large-space;
  margin-top: 2rem;
  background: $white;

  .tab-pane {
    padding-top: $medium-space;
  }

  .nav-tabs {
    border: none;
    border-bottom: $gray-light 2px solid;

    .nav-link {
      color: $gray;
      border: 0 solid transparent;

      &.active {
        color: $brand-primary;
        border: none;
        border-bottom: $brand-primary 3px solid;
      }

      &:hover {
        border: none;
        border-bottom: $brand-primary 3px solid;
      }
    }

    .nav-item {
      float: left;
      margin-bottom: -0.125rem;
    }
  }
}

.product-cover {
    position: relative;
    float: left;
    margin-bottom: $medium-space;
    margin-left: 12px;
    
    @media all and (max-width: 992px) {
        display: none;
    }
    
    img {
      background: $white;
    }

    .layer {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        background: $black;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        @include transition(opacity 0.7s ease-in-out);

        &:hover {
          opacity: 1;
        }

        .zoom-in {
          font-size: 3.125rem;
          color: $btn-primary-color;
        }
    }
}

#product-modal {
  .modal-content {
    padding: 0;
    background: transparent;
    border: none;

    .modal-body {
      display: flex;
      margin-left: -30%;

      .product-cover-modal {
        background: $white;
      }

      .image-caption {
        width: 800px;
        padding: 0.625rem 1.25rem;
        background: $white;
        border-top: $gray-light 1px solid;

        p {
          margin-bottom: 0;
        }
      }

      .thumbnails {
        position: relative;
      }

      .mask {
        position: relative;
        z-index: 1;
        max-height: 49.38rem;
        margin-top: 2.188rem;
        overflow: hidden;

        &.nomargin {
          margin-top: 0;
        }
      }

      .product-images {
        margin-left: $extra-large-space;

        img {
          width: 9.25rem;
          cursor: pointer;
          background: $white;

          &:hover {
            border: $brand-primary 3px solid;
          }
        }
      }

      .arrows {
        position: absolute;
        top: 0;
        right: $large-space;
        z-index: 0;
        width: 100%;
        height: 100%;
        color: $white;
        text-align: center;
        cursor: pointer;

        .arrow-up {
          position: absolute;
          top: -2rem;
          opacity: 0.2;
        }

        .arrow-down {
          position: absolute;
          bottom: -2rem;
        }

        i {
          display: inline;
          font-size: 6.25rem;
        }
      }
    }
  }
}

#blockcart-modal {
  color: $gray-darker;

  .modal-header {
    background: $white;
    border-bottom: 0;
    
    @media all and (max-width: 992px) {
        padding: 26px 15px 3px;
    }
    
    .close {
      opacity: 1;

        .material-icons {
            color: $gray-dark;
            margin: 10px 0px 0px 10px;
            
            @media all and (max-width: 992px) {
                margin: 0;
            }
        }
    }
  }

  .modal-body {
        padding: 0px 20px 15px 20px;
        background: $btn-warning-color;

    .divide-right span {
      display: inline-block;
      margin-bottom: 0.3125rem;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0 0.5rem;
      }
    }
  }

  .modal-dialog {
    width: 100%;
    max-width: 522px;
  }

  .product-image {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0 0 0 auto;

    @include media-breakpoint-down(md) {
      max-width: 70%;
      margin: 0 auto 1rem;
    }
    
    @media all and (max-width: 992px) {
        display: none;
    }
  }

    .modal-title {
        font-size: 25px;
        font-weight: 700;
        color: #000000;
        
        @media all and (max-width: 992px) {
            font-size: 16px;
            font-weight: 600;
        }

    i.material-icons {
      margin-right: $large-space;
      color: $brand-success;
    }
  }

  .product-name {
    font-size: 15px;
    color: #000000;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 13px;

    @include media-breakpoint-down(sm) {
      padding: 0 0.5rem;
    }
    
    @media all and (max-width: 992px) {
        display: none;
    }
  }

  .product-price {
    display: block;
    color: #000000;
    font-size: 17px;
    font-weight: 700;
    
    .cart-qt {
        font-weight: 400;
    }
    
    @include media-breakpoint-down(sm) {
      padding: 0 0.5rem;
    }
    
    @media all and (max-width: 992px) {
        display: none;
    }
  }

  .cart-content {
        .free-shipping {
            padding: 30px;
            background: #f8f8f8;
            text-align: center;
            font-size: 17px;
            font-weight: 700;
            border-top: 2px solid #f3f3f3;
            margin-bottom: 19px;
            margin-top: 25px;
            
            @media all and (max-width: 992px) {
                display: none;
            }
            
            .truck-icon {
                vertical-align: top;
                margin-right: 10px;
            }
        }  
      
    @include media-breakpoint-up(md) {
      padding-left: 0;
    } 

    .btn {
      margin-bottom: $small-space;
    }

    p {
        display: flex;
        justify-content: space-between;
        color: black;
        margin-bottom: 2px;
        
        @media all and (max-width: 992px) {
            display: none;
        }
        
        &.product-total {
            margin-top: 15px;
            
            .label, .value {
                font-size: 20px;
                font-weight: 700;
            }
        }

      &.cart-products-count {
        font-size: 1rem;
        font-weight: 600;
        color: $btn-tertiary-color;
      }

      &.product-tax {
        display: inherit;
        font-size: 0.875rem;
      }

      .label,
      .value {
        font-weight: 500;
      }
    }

    .cart-content-btn {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        margin-top: 21px;
        
        @media all and (max-width: 992px) {
            flex-direction: column;
        }

        button {
          margin-right: 0.9rem;
        }

        .btn {
            white-space: inherit;
            text-transform: none;
            margin: 0;    
            
            @media all and (max-width: 992px) {
                font-size: 15px;
            }

            &.btn-secondary {
                background-color: white;
                border: 2px solid #ededed;
                padding: 12px 34px;
                
                @media all and (max-width: 992px) {
                    order: 2;
                }
                
                
                &:hover {
                    background-color: #ededed;
                }
            }

            &.btn-primary {
                background: #000;
                line-height: 1;
                padding: 14px 43px;
                
                @media all and (max-width: 992px) {
                    background: #e44a6d;
                    order: 1;
                    margin-bottom: 10px;
                }
                
                &:hover {
                    background-color: #e1375c;
                }
            }
        }
    }
  }

  .divide-right {
    border-right: 1px solid $gray-light-second;
  }
}

.product-images {
  > li.thumb-container {
    display: block;

    > .thumb {
      margin-bottom: $small-space;
      cursor: pointer;

      .images-container & {
        margin-right: 0.8125rem;
      }

      &.selected,
      &:hover {
        border: black 1px solid;
      }
    }
  }
}

#main {
    .images-container {
        .js-qv-mask {
            overflow: hidden;
            white-space: nowrap;
            
            @media all and (max-width: 992px) {
                overflow: initial;
                white-space: initial;
            }
            &.scroll {
                width: 90px;
                margin: 0 auto;
                float: left;
            }
        }
    }
}

.scroll-box-arrows {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    
    &.scroll {
        display: block;
    }

    i {
        position: absolute;
        bottom: 1.625rem;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
    }

    .arrow-up {
        left: 0;
        display: none;
    }

    .arrow-down {
        right: 0;
        left: 30px;
    }
}

#product-availability {
  display: inline-block;
  margin-top: 0.625rem;
  font-weight: 700;

  .material-icons {
    line-height: inherit;
  }

  .product-available {
    color: $brand-success;
  }

  .product-unavailable {
    color: $brand-warning;
  }

  .product-last-items {
    color: $brand-warning;
  }
}

#product-details {
  .label {
    font-size: $font-size-base;
    font-weight: 500;
    color: $gray-darker;
  }
}

.product-features {
  margin-top: $medium-space;
  margin-left: 0.3125rem;

  > dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    dd.value,
    dt.name {
      flex: 1 0 40%;
      min-height: 2.5rem;
      padding: $small-space;
      margin-right: $small-space;
      margin-bottom: 0.5rem;
      font-weight: 500;
      text-transform: capitalize;
      word-break: normal;
      background: $gray-light;

      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
  }
}

.product-variants {
  > .product-variants-item {
    margin: $medium-space 0;

    select {
      width: auto;
      padding-right: 1.875rem;
      background-color: $white;
    }

    ul li {
      margin-right: $small-space;
    }

    .color {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.product-flags {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  pointer-events: none;

  li.product-flag {
    width: fit-content;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    pointer-events: auto;
    background: $brand-primary;
    margin-bottom: 3px;
    
    @media all and (max-width: 992px) {
        line-height: 1;
        padding: 4px 6px;
        font-size: 10px;
    }

    &.online-only {
      top: 25rem;
      right: 0;
      z-index: 1;
      margin-top: 0;
      font-size: $font-size-xs;

      &::before {
        margin: 0.3125rem;
        font-family: "Material Icons", Arial, sans-serif;
        vertical-align: middle;
        content: "\E30A";
      }
    }

    &.discount-percentage,
    &.discount-amount,
    &.discount {
        background-color: $brand-primary;
    }
    
    &.special-label-cat {
        background-color: black;
    }
    
    &.discount {
        right: 0;
        border-radius: 50%;
        width: 49px;
        height: 49px;
        line-height: 49px;
        text-align: center;
        padding: 0;
        padding-left: 1px; 
        right: 0;
        top: 0;
        position: absolute;
        
        @media all and (max-width: 992px) {
            width: 28px;
            height: 28px;
            line-height: 28px;
            font-size: 9px;
            text-align: center;
            padding: 0;
        }
    }
    
    &.new {
        background: black;
    }

    &.on-sale {
      order: -1;
      // stylelint-disable-next-line
      text-align: center;
      background: $brand-primary; 
    }
  }
}

.product-customization {
  margin: $extra-large-space 0;

  .product-customization-item {
    margin: $medium-space 0;
  }

  .product-message {
    width: 100%;
    height: 3.125rem;
    padding: 0.625rem;
    resize: none;
    background: $gray-light;
    border: none;

    &:focus {
      background-color: $white;
      outline: 0.1875rem solid $brand-primary;
    }
  }

  .file-input {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 2.625rem;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
  }

  .customization-message {
    margin-top: 20px;

    .customization-label {
      text-align: left;
      word-break: break-word;
    }
  }

  .custom-file {
    position: relative;
    display: block;
    width: 100%;
    height: 2.625rem;
    margin-top: $medium-space;
    line-height: 2.625rem;
    color: $gray;
    text-indent: 0.625rem;
    background: $gray-light;

    button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
    }
  }

  small {
    color: $gray;
  }
}

.product-pack {
  margin-top: $extra-large-space;

  .pack-product-container {
    display: flex;
    justify-content: space-around;

    .pack-product-name {
      flex: 0 0 50%;
      font-size: 0.875rem;
      color: $gray;
    }

    .pack-product-quantity {
      padding-left: $small-space;
      border-left: $gray-light 2px solid;
    }

    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}

.product-refresh {
  margin-top: $medium-space;
}

.social-sharing {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;

  ul {
    margin-bottom: 0;
  }

  li {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: 0.25rem 0.25rem;
    background-size: 2rem 2rem;
    border-radius: 50%;
    @include transition(all 0.2s ease-in);

    a {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;

      &:hover {
        color: transparent;
      }
    }
  }
}

.products-selection {
  margin-bottom: 45px;

  .title {
    color: $gray;
  }
}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
      height: auto;
    }
  }

  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;

    img.product-cover-modal {
      width: 100%;
    }

    .arrows {
      display: none;
    }
  }

  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }

  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }

    .modal-body {
      padding: 0 15px 20px;
    }
  }

  .product-images > li.thumb-container > .thumb:not(.selected) {
    border: none;
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }

    .modal-body {
      padding: 1rem;
    }
  }
}


.products  {
    #add-to-cart-or-refresh {
        @media all and (max-width: 992px) {
            display: none;
        }
    }
    
    .product-quantity {
        width: 100%;

        .add {
            width: 100%;
            opacity:0;
            transition: opacity 0.3s;
            
            @media all and (max-width: 992px) {
                display: none;
            }

            .add-to-cart {
                padding: 12px 18px;
                width: 100%;
                margin-top: 10px;
                font-size: 15px;
                background: black;
                
                &:hover {
                    background-color: #e44a6d;
                }
            }
        }
    }
    
    .product-miniature {
        border: 1px solid transparent;
        padding: 10px 10px 0px 10px;
        transition: border-color 0.3s;
        
        @media all and (min-width: 991px) { 
            &:hover {
                border: 1px solid #ebebeb;

                .add {
                    opacity: 1;
                }
            }
        }
        
        .thumbnail-container {
            margin-bottom: 0;
        }
        
        .product-description {
            padding-bottom: 0;
        }
        
        .product-actions {
            margin-bottom: -15px;
        }
    }
}

#product #data-description  .read-more {
    background: transparent;
    border: none;
    text-decoration: underline;
    color: #7e7d7d;
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 0;

    img {
        margin-left: 15px;
    }
}