
.cart-grid {
  margin-bottom: 1rem;
}

.cart-items {
  margin-bottom: 0;
}

.cart-item {
   margin-bottom: 10px;
   
   @media all and (max-width: 992px) {
       position: relative;
   }
   
    &.headers {
        background: #f8f8f8;
        border: none;
        margin-bottom: 20px;

        @media all and (max-width: 992px) {
            display: none;
        }
        
        .product-line-grid {
            padding: 16px 18px;
            border: none;
        }
       
        .product-line-grid-body,
        .product-line-info,
        .product-line-actions {
            font-size: 15px;
            font-weight: 400;
            color: black;
            padding: 0;
            margin: 0;
            margin-bottom: 0;
        }
    }
    
    .product-line-actions { 
        text-align: center;
        
        @media all and (max-width: 992px) {
            position: static;
        }
        
        .col-md-2.col-xs-2.text-xs-right {
            @media all and (max-width: 992px) {
                position: static;
                width: 0;
                padding: 0;
            }
        }
        
        .bootstrap-touchspin {
            float: none;
            margin-top: 18px;
        }
    }
}

.card-block {
  padding: 2px 0 .5rem;
  
  @media all and (max-width: 992px) {
      padding: 10px 0;
  }

  &.checkout {
    padding-top: 0;
    padding-bottom: 1.75rem;

    .btn-primary {
        width: 100%;
        white-space: normal;
        background-color: black;
        padding: 17px 10px;
        text-transform: none;
        
        &:hover {
            background: #e44a6d;
        }
    }
  }

/*  &.cart-detailed-subtotals + .cart-summary-totals .cart-total {
    border-top: 1px solid $border-gray;
  }*/

  &.cart-summary-totals {
    padding: 0 0 0.825rem;

    .cart-summary-line {
      padding: 0.5rem 0.2rem 0;

      &:last-child {
        margin-bottom: 0;
      }

      &.cart-total {
        padding: 0rem 0 2rem 0;

        .label,
        .value {
          font-weight: 600;
        }

        .label {
          font-size: 19px;
        }

        .value {
          font-size: 1rem;
          
          @media all and (max-width: 992px) {
              font-size: 19px;
          }
        }
      }

      span {
        &.sub {
          float: none;
          font-size: 0.88rem;
          text-align: left;
        }
      }
    }
  }
}

.cart-summary-line {
  @include clearfix();
  margin-bottom: 0.315rem;
  clear: both;

  
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .label {
    padding-left: 0;
    font-weight: 400;
    color: black;
    white-space: inherit;
  }

  .value {
    float: right;
    font-weight: 600;
    color: $gray-darker;
  }

  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: 500;
      
      @media all and (max-width: 992px) {
          font-size: 13px;
      }
    }
  }

  .label,
  .value {
    font-size: 1rem;
  }
}

/** CART BODY **/
.cart-grid-body {
  margin-bottom: 8px;

  a.label {
    &:hover {
      color: $brand-primary;
    }
  }

  .card-block {
    padding: 1rem 0;

    h1 {
        margin-bottom: 25px;
        font-size: 39px;
        font-weight: 600;
        
        @media all and (max-width: 992px) {
            font-size: 21px;
        }
      
        a {
            float: right;
            margin-top: 15px;
            font-weight: 400;
            
            @media all and (max-width: 992px) {
                margin-top: 0;
            }
        }
    }
  }

  .cart-overview {
    padding: 0;
  }
}

/** CART RIGHT **/
.cart-grid-right {
  .promo-discounts {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    text-align: center;

    .cart-summary-line {
      .label {
        font-size: 1rem;
        color: $gray;

        .code {
          color: $warning-second;
          cursor: pointer;
        }
      }
    }
  }

  .cart-summary-line {
    &.cart-subtotal-products {
      .label,
      .value {
        font-weight: 600;
      }

      .value {
        font-size: 1rem;
      }
    }
  }
}

.block-promo {
  margin-bottom: 1rem;
  margin-top: 41px;
    
  .promo-code {
    padding: 1rem 0;

    .alert-danger {
      position: relative;
      display: none;
      margin-top: 1.25rem;
      color: $white;
      background: $brand-danger;

      &::after {
        position: absolute;
        bottom: 100%;
        left: 10%;
        width: 0;
        height: 0;
        margin-left: -10px;
        pointer-events: none;
        content: " ";
        border: solid transparent;
        border-width: 10px;
        border-bottom-color: $brand-danger;
      }
    }

    form {
      text-align: left;
    }
    
    .btn.btn-primary {
        background-color: #000;
        padding: 10px 1.25rem;
        font-weight: 600;
        color: #fff;
        border: none;
        margin-top: -1px;
        
        &:hover {
            background: #e44a6d;
        }
    }
  }

  .promo-input {
    width: 50%;
    height: 2.5rem;
    margin-right: -0.24rem;
    color: $gray-darker;
    text-indent: 0.625rem;
    border: $gray 1px solid;

    + button {
      margin-top: -4px;
      margin-left: -1px;
      text-transform: capitalize;
      border: 2px solid $brand-primary;
    }
  }

  .cart-summary-line .label,
  .promo-name {
    margin-bottom: 0;
    font-weight: 600;
    color: $brand-warning;

    a {
      display: inline-block;
      font-weight: 500;
      color: $gray-darker;
    }
  }

  .promo-name {
    .cart-summary-line {
      .label {
        font-weight: 500;
        color: $gray-dark;
      }

      span {
        color: $warning-second;
      }
    }

    .material-icons {
      margin-left: 0.4rem;
      font-size: 1.2rem;
    }
  }

  .promo-code-button {
    display: block;
    padding: 0 0 1rem;
    text-align: left;

    &.with-discounts {
      display: none;
    }
    
    .collapse-button {
        font-size: 25px;
        font-weight: 700;
        color: black;    
        
        img {
            vertical-align: top;
            margin-right: 2px;
        }
    }

    &.cancel-promo {
      margin-bottom: 0;
      color: $brand-primary;

      &[aria-expanded="true"] {
        display: block;
        padding: 0;
        margin-top: 1rem;
        color: black;
      }
    }
  }

  &.promo-highlighted {
    padding: 0 1rem;
    margin-bottom: 0;
    text-align: center;
    
    display: none;
  }
  
  .promo-discounts {
      display: none;
  }

  p {
    margin-bottom: 0;
  }
}

.product-line-grid {
    border: 1px solid #ededed;
    padding: 27px 18px 27px;
}

/** CONTENT LEFT **/
.product-line-grid-left {
    
    @media all and (max-width: 992px) {
        padding-left: 0;
    }
    
    img {
      max-width: 100%;
    }
}

/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
        padding: 0;
        font-weight: 400;
        line-height: inherit;
        text-align: left;
        white-space: inherit;
        display: block;
        margin-top: 4px;
        
        @media all and (max-width: 992px) {
            font-weight: 300;
        }
    }

    > .out-of-stock {
      color: #f00;
    }

    > .available {
      color: $brand-success;
    }

    .unit-price-cart {
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: 500;
      color: $gray;
      text-overflow: ellipsis;
    }
    
  }
}

 .product-line-info {
     
    &.product-price {
        padding-top: 10px;
        text-align: center;

        @media all and (max-width: 992px) {
            display: none;
        }
    }
     
    .regular-price {
        font-size: 17px;
        font-weight: 600;
        margin-right: 0;
    }
    
    .current-price { 
        font-size: 17px;
        font-weight: 700;
        color: black;
        display: inline;
    }
    
    .reference {
        margin-top: 10px;
        
        .label {
            color: #7e7e7e;
            font-size: 15px;
            margin-right: 5px;
        }
        
        .value {
            font-size: 14px;
        }
    }
 }

.product-line-grid-right {
    .product-price {
        font-size: 17px;
        font-weight: 700;
        
        strong {
            font-weight: 700;
        }
    }
    
    @media all and (max-width: 992px) {
        .qty {
            padding-right: 0;
            
            .bootstrap-touchspin {
                float: left;
            }
            
            input.form-control {
                width: 40px;
            }
        }
        
    }
}

 
/** CONTENT LEFT **/
.product-line-grid-right {
  .cart-line-product-actions,
  .product-price {
    line-height: 36px;
    color: black;
    margin-top: 18px;
    
    .remove-from-cart {
        display: inline-block;
        margin-top: -1px;
        color: #232323;
        vertical-align: top;
        
        @media all and (max-width: 992px) {
            position: absolute;
            top: 7px;
            left: 11px;
        }
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: 0rem;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: 1rem 0;
  }

  .cart-item {

    &:last-child {
      border-bottom: 0;
    }
  }

  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }

    .no-items {
      display: inline-block;
      padding: 1rem;
    }
  }

  .product-line-grid-left {
    // stylelint-disable-next-line
    padding-right: 0 !important;
  }
}
.cart-grid {
    .cart-grid-left {
        
        @media all and (max-width: 992px) {
            display: none;
        }
        
        .btn {
            background: transparent;
            padding: 0;
            margin-right: 15px;
            
            img {
                margin-right: 10px;
            }
        }
    }
    
    @media all and (max-width: 992px) {
        .cart-grid-right {
            .cart-detailed-subtotals {
                display: none
            }
        }
    }
    
}

.checkout-free-delivery {
    background: #f8f8f8;
    text-align: center;
    font-size: 17px;
    padding: 33px 0;

    @media all and (max-width: 992px) {
        font-size: 14px;
        padding: 13px 0;
        font-weight: 500;
    }

    .truck-image {
        margin-right: 10px;
    }
     
    .free-delivery-amount {
        font-weight: 600;
    }
}

.cart-suggestions {
    .product-miniature {
        .product-title a {
            font-size: 12px;
        }
       
        .product-price-and-shipping {
            font-size: 16px;
            margin-top: 23px;   
        }
    }
    
    .add {
        width: 100%;
        
        .add-to-cart {
            font-size: 14px;
            width: 100%;
            margin-top: 13px;
        }
    }
}


.cart-suggestions {
    .h2 {
        text-align: center;
        font-size: 25px;
        margin-top: 21px;
        
        @media all and (max-width: 992px) {
            font-size: 20px;
            margin-top: 28px;
        }
    }
    
    .subtitle {
        text-align: center;
        font-weight: 300;
        margin-bottom: 35px;
    }
    
    @media all and (max-width: 992px) {
        .product-quantity .add {
            width: 100%;
        }
    }
}