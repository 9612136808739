/* stylelint-disable */
@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "~slick-slider/slick/slick.css";
@import "~slick-slider/slick/slick-theme.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/subcategories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/newsletter";
@import "components/brands";
@import "components/header";
@import "components/wrapper";
@import "components/main";
@import "components/notifications";
/* stylelint-enable */

.blockcart {
    .header {
        display: inline-block;
        position: relative;
        
        .cart-products-count {
            position: absolute;
            top: -7px;
            background: #e44a6d;
            text-align: center;
            border-radius: 50%;
            height: 18px;
            line-height: 18px;
            font-size: 13px;
            color: white;
            font-weight: 600;
            padding: 0 5px; 
            right: -8px;
            min-width: 17px;
            
            @media all and (max-width: 991px) {
                top: -4px;
                height: 17px;
                line-height: 17px;
                right: -8px;
                font-size: 10px;
                min-width: auto;
            }
        }
    }
}

@media all and (max-width: 991px) {
    #header .top-logo img {
        max-width: 128px;
        max-height: initial;
        margin-top: 25px;
        margin-left: -1px;
    }
    
    #header #_mobile_user_info img {
        max-width: 17px;    
    }
} 

.blockcart {
    
    .dropdown-menu.mini-cart {
        width: 400px;
        margin-top: 20px;
    
        @media all and (max-width: 991px) {
            width: 300px;
        }
        
        .mini-cart-product {
            position: relative;
            padding: 12px 0 5px;
            border-bottom: 2px solid #f6f6f6;
            margin: 0 13px;
            
            .product-name {
                font-weight: 500;
                font-size: 15px;
                line-height: 1.6;
                margin-top: 12px;
            }
            
            .mini-cart-product-price {
                font-weight: 700;
                font-size: 18px;
                margin-top: 6px;
            }
            
            .remove-from-cart {
                position: absolute;
                left: 13px;
                top: 12px;
            }
            
            .product-quantity { 
                display: inline;
            }
        }
        
        .mini-cart-summary-free-delivery {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.3px;
            margin: 0 15px;
            border-bottom: 2px solid #f6f6f6;
            
            @media all and (max-width: 992px) {
                font-size: 12px;
                letter-spacing: 0px;
                margin: 0;
            }
            
            .center-box {
                max-width: 200px;
                margin: 0 auto;
                padding-bottom: 18px;
                
                @media all and (max-width: 992px) {
                    max-width: initial;
                }

                .free-shipping-reached {
                    color: #e44a6d;
                }
            }
        }
        
        .mini-cart-subtotals {
            padding: 0 15px;
            margin-top: 20px;
        }
        
        .cart-summary-line {
            label {
                font-size: 15px;
            }
            
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
        
        .mini-cart-summary-totals {
            padding: 0 15px;
            
            .mini-cart-summary-line {
                font-size: 21px;
                font-weight: 700;
                
                @media all and (max-width: 992px) {
                    font-size: 16px;
                }
                
                .label {
                    font-size: 21px;
                    
                    @media all and (max-width: 992px) {
                        font-size: 16px;
                    }
                }

                .value {
                    float: right;
                }
            }
        }
        
        .mini-cart-checkout {
            padding: 0 15px;
            margin-top: 25px;
            margin-bottom: 20px;
    
            .checkout {
                background: black;
                text-transform: none;
                width: 100%;
                padding: 15px;
                font-size: 16px;
                letter-spacing: 0;

                @media all and (max-width: 992px) {
                    font-size: 14px;
                }

                &:hover {
                    background: #e44a6d;
                }
            }
        }
    }
    
    .dropdown-toggle::after {
        display: none;
    }
}

.carousel-inner>.carousel-item {
    margin: 0 auto;
    text-align: center;
}

.advantages-home {
    display: flex;
    justify-content: center;
    margin: 33px 0px;
    
    @media all and (max-width: 992px) {
        margin-top: 0;
    }
    
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    
    .slick-prev {
        left: -9px;
        display: none!important;
    }
    
    .slick-next {
        right: -9px;
        display: none!important;
    }
    
    
    .slick-next:before, .slick-prev:before {
        display: block;
        width: 25px;
        height: 25px;
        border: 1px solid #cfcfcf;
        border-radius: 50%;
        content: "";
    }
    
    .block {
        padding: 2px 50px;
        border-right: 1px solid #ededed;
        
        @media all and (max-width: 1200px) {
            padding: 2px 18px;
        }
        
        @media all and (max-width: 992px) {
            border-right: none;
            text-align: center;
        }
        
        &:nth-last-of-type(1) {
            border-right: none;
        }
        
        .image-holder {
            float: left;
            
            @media all and (max-width: 992px) {
                float: none;
                display: inline-block;
                text-align: left;
            }
        }
        
        .text {
            float: left;
            margin-left: 19px;
            
            @media all and (max-width: 992px) {
                float: none;
                display: inline-block;
                text-align: left;
            }
            
            .small {
                display: block;
                letter-spacing: 0.45px;
                font-size: 16px;
                font-weight: 600;
                margin-top: -7px;
                
                @media all and (max-width: 1600px) {
                    font-size: 12px;
                }
            }
            
            .big {
                font-size: 40px;
                display: block;
                font-weight: 700;
                letter-spacing: 1px;
                margin-top: 14px;
                
                @media all and (max-width: 1600px) {
                    font-size: 23px;
                }
                
                @media all and (max-width: 992px) {
                    font-size: 36px;
                }
            }
        }
    }
}

.featured-products {
    margin-top: 14.5vh;
    
    @media all and (max-width: 992px) {
        margin-top: 0;
    }
    
    &.bestsellers {
        margin-top: 10vh;
    }
}

.home-section-title {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 1px;
    margin-bottom: 32px;
    text-align: center;
    
    @media all and (max-width: 992px) {
        font-size: 19px;
        margin-bottom: 12px;
    }
    
    &:before, &:after {
        content: "";
        display: block;
        height: 2px;
        background: #f6f6f6;
        width: 100%;
        position: absolute;
        top: 50%;
    }

    &:before {
        transform: translateX(-50%);
    }

    &:after {
        transform: translateX(50%);
    }
    
    span {
        background: white;
        position: relative;
        z-index: 9;
        padding: 0 30px;
    }
}

.watch-youtube {
    .home-section-title {
        margin-top: 15vh;
        
        span {
            margin-left: -20px;
            
            @media all and (max-width: 992px) {
                margin-left: 0;
                padding: 0 5px;
            }
            
            img {
                margin-left: 8px;
                vertical-align: top;
                
                @media all and (max-width: 992px) {
                    margin-left: 3px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -41px;
                    max-width: 46px;
                }
            }
        }
    }
    
    .all-product-link {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: .45px;
        margin: 0 auto;
        display: table;
        margin-bottom: 6vh;
        
        @media all and (max-width: 992px) {
            font-size: 15px;
            font-weight: 500;
        }
        
        img {
            vertical-align: middle;
            margin-left: 6px;
            margin-top: -3px;
        }
    }
    
    @media all and (max-width: 992px) {
        .miniatures-container {
            text-align: center;
            
            img {
                margin-bottom: 20px;
            }
        }
    }
}

.products-home-slider {
    .products-section-title {
        position: relative;
        font-size: 38px;
        font-weight: 600;
        text-transform: none;
        letter-spacing: 1px;
        margin-bottom: 22px;
        
        @media all and (max-width: 992px) {
            font-size: 23px;   
            letter-spacing: 0px;    
            margin-top: 30px;
        }
        
        span {
            background: white;
            position: relative;
            z-index: 9;
            padding: 0 30px; 
            
            @media all and (max-width: 992px) {
                display: block;
                max-width: 250px;
                margin: 0 auto;
            }
        }
        
        &:before, &:after {
            content: "";
            display: block;
            height: 2px;
            background: #f6f6f6;
            width: 100%;
            position: absolute;
            top: 50%;
        }
        
        &:before {
            transform: translateX(-50%);
        }
        
        &:after {
            transform: translateX(50%);
        }
    }
    
    .all-product-link {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        margin: 0 auto;
        display: table;
        margin-bottom: 8vh;
        
        @media all and (max-width: 992px) {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
        }

        
        svg {
            vertical-align: middle;
            margin-left: 6px;
            margin-top: -3px;
            transition: margin-left 0.3s;
        }
        
        &:hover {
            svg {
                margin-left: 12px;
                line, path {
                    stroke: #e1375c;
                }
            }
        }
    }
}

#index {
    .middle-banners {
        margin-top: 9vh;
        
        @media all and (max-width: 992px) {
            .col-md-6 {
                &:nth-child(1) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.slick-next, .slick-prev {
    width: auto;
    height: auto;
    z-index: 9;
}

.slick-next::before, [dir="rtl"] .slick-prev::before {
    content: ""!important;
}

.slick-next:before, .slick-prev:before {
    display: block;
    width: 70px;
    height: 70px;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
    background-color: white;
    opacity: 1;
}

.slick-prev {
    left: -70px;
    @media all and (max-width: 992px) {
        left: 5px;
    }
}

.slick-next {
    right: -70px;
    
    @media all and (max-width: 992px) {
        right: 5px;
    }
}

.slick-next:before {
    background: url(../../assets/img/trena_assets/svg/slider-arrow-right.svg) no-repeat center;
    background-color: white;
}

.slick-next:hover:before {
    content: "";
    background: url(../../assets/img/trena_assets/svg/slider-right-hover.svg) no-repeat center; 
}

.slick-prev:before {
    background: url(../../assets/img/trena_assets/svg/slider-arrow-left.svg) no-repeat center;
    background-color: white;
}

.slick-prev:hover:before {
    content: "";
    background: url(../../assets/img/trena_assets/svg/slider-left-hover.svg) no-repeat center;
}

.toolsforbeauty.products-home-slider {
    .top-belt-menu {
        text-align: center;
        margin-bottom: 6vh;
            
        .link {
            cursor: pointer;
            display: inline-block;
            margin: 0 25px;
            color: #999999; 
            font-weight: 500;
            opacity: 0.7;
            
            @media all and (max-width: 992px) {
                margin: 0 12px;
                font-size: 15px;
                font-weight: 400;
            }
            
            &:hover {
                opacity: 1;
            }
            
            &.active {
                color: black;
                font-weight: 700;
                
                @media all and (max-width: 992px) {
                    font-weight: 500;
                }
            }
        }
    }
    
    .manufacturer-container {
        display: none;
        
        &.active {
            display: block;
        }
        
        @media all and (max-width: 992px) {
            .manu-link {
                img {
                    margin-bottom: 30px;
                }
            }
        }
    }
    
    .slick-prev, .slick-next {
        bottom: -115px;
        top: auto;
        left: auto;
        z-index: 9;
        
        @media all and (max-width: 1230px) {
            bottom: 45%;
        }
    }
    
    .slick-prev {
        right: 102px;
        
        @media all and (max-width: 1230px) {
            right: auto;
            left: -27px;
        }
    }
    
    .slick-next {
        right: 18px;
        
        @media all and (max-width: 1230px) {
            right: -40px;
        }
    }
}

    
.text-footer {
    padding-top: 70px;
    padding-bottom: 0;
    margin-bottom: 0;
    height: 192px;
    overflow: hidden;
    position: relative;
    border-top: 2px solid #f6f6f6;
    
    .h3 {
        font-size: 20px;
        margin-bottom: 25px;
    }
    
    p {
        line-height: 1.7;
        font-size: 14px;
    }
    
    @media all and (max-width: 992px) {
        display: none;
    }
}

.read-more-container {
    @media all and (max-width: 992px) {
        display: none;
    }
    
    .read-more {
       cursor: pointer;
       text-decoration: underline;
       font-size: 13px;
       margin-top: 6px;
       display: inline-block;
   }
}

.text-footer.active {
    height: auto;
    margin-bottom: 0;
}

#js-product-list-header {
    .sorting {
        float: right;
        margin-top: 39px;
        font-size: 15px;
        
        @media all and (max-width: 992px) {
            margin-top: 6px;
            width: 100%;
        }
    }
}

.products-sort-order {
    .select-title {
        border: none;
        width: auto;
        text-decoration: underline;
        font-weight: 700;
        padding: 0;
        
        @media all and (max-width: 992px) {
            width: 100%;
            border: 1px solid #ebebeb;
            padding: 10px;
            color: #8f8f8f;
            text-decoration: none;
            font-weight: 300;
        }
        
        img {
            margin-left: 15px;
            
            @media all and (max-width: 992px) {
                float: right;
                margin-top: 6px;
            }
        }
    }
}


.manufacturer_left_column_search {
    margin-bottom: 23px;
    
    @media all and (max-width: 992px) {
        display: none;
    }
    
    input {
        padding: 7px 2px 6px;
        border: none;
        border-bottom: 2px solid #000000;
        max-width: 100%;
        margin-left: 0;
        font-size: 12px;
        letter-spacing: .75px;
        line-height: 12px;
        width: 100%;
        text-transform: none;
        outline: none;

        &:placeholder {
            text-transform: none;
        }

        &::focus-visible {
            outline: none;
        } 
    }
}

.manufacturer_left_column_search .search-button {
    background: transparent;
    height: 35px;
    width: 41px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
    line-height: 31px;
    text-align: center; 
    color: black;
    position: absolute;
    right: 10px;
    
    i {
        font-size: 36px;
    }
} 

#search_filters {
    .facet {
        .magnitude {
            color: #9f9f9f;
            font-size: 14px;
        }
    }
}

#product .product-prices div {
    margin-bottom: 0;
}

#product #data-delivery .h3 {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 23px;
}

#product #data-delivery .table {
    margin-bottom: 30px;
    max-width: 527px;
}

#product #data-delivery .table td {
    padding-left: 0;
}

#product #data-delivery .table .price {
    font-weight: 700;
    text-align: right;
    font-size: 16px;
}

#cart #content-wrapper {
    max-width: 1445px;
    margin: 0 auto;
    float: none;
}

body .wishlist-footer-links > a {
    color: black;
}

#index #carousel-mobile {
    display: none;
}

#index .carousel {
    background-color: white!important;
    max-width: 1920px;
    @media all and (min-width: 991px) {
        margin: 1.5rem auto;    
    }
}
    
@media all and (max-width: 992px) {
    .slick-dots li {
        width: 16px;
        margin: 0px 0px;
    }
    
    #index #carousel-desktop {
        display: none;
    }
    
    #index #carousel-mobile {
        display: block;
    }
}

#index .carousel-control { 
    left: 20px;
}

#index #carousel-desktop .carousel-control .icon-prev {
    left: 3rem;
}

#index #carousel-desktop .carousel-control .icon-next {
    right: 3rem;
}


#index .carousel-control.right {
    right: 20px;
    left: auto;
}


.user-box.__account .log-in,
.final_summary_box button[name="submit_order"],
.user-box.__account .register {
    &:hover {
        color: white;
        background: #e1375c;
        border-color: #e1375c;
    }
}

.arrow-totop {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: -100px;
    right: 70px;
    background-repeat: no-repeat;
    background-position: 50% 49%;
    cursor: pointer;
    border-radius: 50%;   
    background-color: white;
    background-image: url('../../assets/img/trena_assets/svg/arrow-totop-grey.svg');
    border: 1px solid #ebebeb;
    transition: bottom 0.3s;
    z-index: 999;
    
    &:hover {
        background-color: #e1375c;
        background-image: url('../../assets/img/trena_assets/svg/arrow-totop-white.svg'); 
        border-color: #e1375c;
    }
    
    &.active {
        bottom: 100px;
    }
    
    @media all and (max-width: 992px) {
        width: 50px;
        height: 50px;
        right: 20px;
        
        &:hover {
            background-color: inherit;
            background-image: inherit;
            border-color: inherit;
        }
        
        &.active {
            bottom: 30px;
        }
    }
}  


#thank-you {
    @media only screen  and (max-width: 760px), (min-device-width: 768px)  and (max-device-width: 1024px)  {
        table.table-products-summary {
            thead, tbody, th, td, tr {
                 display: block;
            }

            tr th {
                display: none;
            }


            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr {
                margin: 0;
            }

            tr:nth-child(even) {
               background: #f9f9f9;
            }

            td {
                border: none;
                border-bottom: 1px solid #eee;
                position: relative;
                padding-left: 50%;
                font-size: 12px;
            }

            td:before {
                position: absolute;
                top: 0;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                transform: translate(5%, 50%);
                font-weight: 600;
            }

            td:nth-of-type(1):before { 
content: "Nr referencyjny"; 
}
            td:nth-of-type(2):before { 
content: "Produkt"; 
}
            td:nth-of-type(3):before { 
content: "Ilość"; 
}
            td:nth-of-type(4):before { 
content: "Cena jednostkowa"; 
}
            td:nth-of-type(5):before { 
content: "Razem"; 
}

            .summary {
                float: left;
                width: 100%;
                margin-bottom: 0;
                background: black!important;

                &.summary-total {
                    padding-bottom: 15px;
                }
                
                td {
                    padding-left: 16px;
                    width: 50%;
                    float: left;
                    margin-bottom: 0;
                    color: #fff;
                    border-bottom: none;
                    font-size: 14px;
                    padding: 10px 20px 0px 17px;

                    &:before {
                        display: none;
                    }

                    &:nth-child(odd) {
                        strong {
                            font-weight: 500;
                        }
                    }

                    &:nth-child(even) {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.wishlist-add-all-to-cart {
    margin: 0 45px 50px;
}

.submit-reorder {
    background: #e1375c;
    padding: 10px 30px;
    font-weight: 500;
    color: white;
    
    &:hover {
        color: white;
    }
    
    @media all and (max-width: 992px) {
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 0;
    }
}

.wishlist-button-add:hover i {
    color: #e1375c;
}


.all-product-link {
    &:hover {
        color: #e1375c!important;
    }
}
        


@media all and (min-width: 991px) {
    #search_filters_wrapper {
        > .accept {
            display: none;
        }
        
        .js-search-filters-clear-all {
            display: none;
        }
    }
}

.wishlist-toast.success {
    background-color: #e1375c!important;
    border-color: #e1375c!important;
}


body .slick-dots {
    @media all and (max-width: 992px) {
        bottom: -5px;
    }
}

.checkout-free-delivery .free-shipping-reached {
        font-weight: 600;
}

@media all and (max-width: 992px) {
    .toolsforbeauty-html {
        position: relative;
        
        .menu-mobile-grover {
           position: absolute;
           z-index: 9;
           width: 100%;
           height: 100%;
       }
    }
}