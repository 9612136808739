.customization-modal {
  .modal-content {
    border: 1px solid $gray-lighter;
    border-radius: 0;

    .modal-body {
      padding-top: 0;

      .product-customization-line {
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        border-bottom: 1px solid $gray-lighter;

        .label {
          font-weight: 600;
          text-align: right;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}
