#wrapper {
    padding-top: 0;
    background: white;
    overflow-x: hidden;

  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }

  .breadcrumb {
    padding: 0;
    background: 0 0;
    margin-top: 18px;
    margin-bottom: 38px;
    font-size: 12px;
    
    @media all and (max-width: 992px) {
        display: none;
        margin-top: 6px;
        margin-bottom: 11px;
        font-size: 12px;
    }
    
    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        margin: 0 1px;
        color: $gray;
        content: "/";
      }

      &:last-child {
        margin: 0;
        color: $gray;
        content: "/";

        &::after {
          content: "";
        }
      }

      a {
        color: $gray-darker;
      }
    }
  }
}
