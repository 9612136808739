$thumbnail-size: 250px;
$product-description-height: 70px;

#products,
.featured-products,
.product-accessories {
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .products-section-title {
    margin: 2.5rem 0;
    font-weight: 500;
    text-align: center;
  }

  .all-product-link {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    clear: both;
    font-weight: 500;
    color: $gray;

    @at-root .lang-rtl & {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
  
  .product-variants {
      display: none;
  }
  
  .product-quantity {
    .qty {
        display: none;
    }   
    
    .add {
        display: block;
        width: 100%;   
    }
    
    .add-to-cart {
        width: 100%;
        background: black;
        
        &:hover {
            background-color: #e44a6d;
        }
    }
  }
}

.product-miniature {
  display: flex;
  justify-content: center;

  .product {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 0.8125rem;

    @at-root .page-index &, .page-search & {
      width: 25%;
      min-width: 250px;
    }
  }

  .product-thumbnail {
    display: block;
  }

    .product-title a {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        line-height: 1.5;
        text-decoration: none;
        
        &:hover {
            color: #e1375c;
        }
        
        @media all and (max-width: 992px) {
            font-size: 11px;
            font-weight: 400;
        }
    }

  .thumbnail-container {
    position: relative;
    height: auto;
    margin-bottom: 1.563rem;
    overflow: hidden;
    background: $white;
    
    @media all and (max-width: 992px) {
        margin-bottom: 5px;
    }

    &:hover,
    &:focus {
      .highlighted-informations {
        top: calc(100% - 4.4rem);

        &::after {
          opacity: 1;
        }

        &.no-variants {
          top: calc(100% - 2.5rem);
        }
      }
    }

    .product-thumbnail {
      position: relative;
      height: calc(100% - #{$product-description-height});

      img {
        position: relative;
        display: block;
        max-width: 100%;
        height: auto;
        margin: auto; 
      }
    }
  }

  .product-title {
    margin-top: 0;
    text-align: center;
    text-transform: capitalize;
    padding: 0 20px;
    
    @media all and (max-width: 772px) {
        overflow: hidden;
        max-height: 66px;
    }
  }

  .product-price-and-shipping {
    font-weight: 600;
    color: black;
    text-align: center;
    font-size: 23px;
    margin-top: 31px;
    
    @media all and (max-width: 992px) {
        color: #000;
        text-align: center;
        font-size: 15px;
        margin-top: 10px;
    }
    
    &.has-discount {
        color: #e1375c;
    }

    .discount-product {
      display: none;
    }
  }

  .variant-links {
    position: relative;
    top: -0.25em;
    width: 100%;
    min-height: 2.5rem;
    padding-top: 0.1875rem;
    text-align: center;
    background: $white;
  }

  .thumbnail-top {
    position: relative;
    overflow: hidden;
  }

  .highlighted-informations {
    position: absolute;
    top: 100%;
    z-index: 2;
    width: 100%;
    height: auto;
    padding: 0.625rem 0;
    text-align: center;
    background: $white;
    transition: 0.3s;

    .quick-view {
      font-size: $base-font-size;
      color: $gray;

      &:hover {
        color: $brand-primary;
      }
    }
  }

  .product-description {
    position: relative;
    bottom: 0;
    height: auto;
    padding: 0.25rem;
    padding-bottom: 0.7rem;
    background: $white;
  }

  .product-flags {
    li.product-flag {
      font-weight: 300;

      &.online-only {
        top: 13.1rem;
      }
    }
  }

  .comments_note {
    color: $gray;
    text-align: center;
  }

  .regular-price {
    display: inline-block;
    font-weight: 400;
    font-size: 17px;
    color: #7a7a7a;
    text-decoration: line-through;
    margin-right: 13px;
    
    @media all and (max-width: 992px) {
        margin-right: 2px;
        font-size: 13px;
    }
  }

  .count {
    position: relative;
    bottom: 0.5rem;
    font-weight: 700;
    color: $gray;
  }
}

@media all and (max-width: 992px) {
    .featured-products {
        .products {
            justify-content: center;
            
            .js-product {
                padding: 0;
            }
        }
    }
}
