.block_newsletter {
    @include search-box();
    float: inherit;
    width: 100%;
    max-width: 50rem;
    margin: auto;
    margin-bottom: 0.625rem;
    font-size: $font-size-sm;
    color: white;
    
    .btn-primary {
        padding: 0.5rem 39px;
        font-weight: 500;
        font-size: 15px;
        text-transform: none;
        transition: none;
        
        &:hover {
            background: #e7e7e7;
            border-color: #e7e7e7;
            color: #000;
        }
    }
  
    .agreement {
        margin-top: 9px;
        
        a {
            color:white;
            text-decoration: underline;
        }
    }
    
    .h5 {
        color: white;
        font-size: 42px;
        
        @media all and (max-width: 992px) {
            font-size: 26px;
            font-weight: 500;
            text-align: center;
            margin-top: 35px;
        }
    }
  
    #block-newsletter-label {
        color: white;
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 31px;
    }

    form {
      .input-wrapper {
        overflow: hidden;
      }

      .row {
        .col-xs-12:last-of-type {
          p {
            font-size: 13px;
            letter-spacing: .3px;
            font-weight: 300;
            color: #fff;
            
            @media all and (max-width: 992px) {
                font-size: 11px;
                text-align: center;
            }
          }
        }
      }

        input[type="email"] {
            width: 100%;
            padding: 13px 18px;
            border: 1px solid #ddd;
            font-weight: 300;
            font-size: 16px;

            &:focus {
                border: 3px $brand-primary solid;
                border-right: none;
                outline: 0;
            }
        }

        input {
            height: 50px;
            box-shadow: none;
        }
    }
}

#footer {
  .block_newsletter {
    p {
      padding-top: $small-space;
    }

    #block-newsletter-label {
      padding-top: 0;
      
        @media all and (max-width: 992px) {
            text-align: center;
            font-weight: 300;
            margin-top: 20px;
        }
    }
  }
}

#left-column {
  .block_newsletter {
    padding: 1.563rem 1.25rem;
    margin-bottom: 1.563rem;
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-top: 1rem;
  }
}
